.welcome-content {
    display: flex;
    flex-direction: row;
    
  }

  .title-left-align {
    text-align: left;
    width: 100%;  
    font-size: 56px;
    font-family: -apple-system, "system-ui", "Segoe UI", sans-serif;
  }
  .centered-content {
    margin-left: 10%;
    margin-right: 5%;
    flex: 0 1 61.8%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;  
    font-size: 21px;
    font-weight: bold;
    font-family: -apple-system, "system-ui", "Segoe UI", sans-serif;
  }
  .right-content {
    flex: 0 1 38.2%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white; 
    border-radius: 30px; 
    margin-right: 5%;
    width: auto;
  }
  .inner-black-box {
    background-color: black;  
    border-radius:10px;  
    padding: 20px;  
    margin: 0 5%;  
    width: 90%;  
    height: auto;
  }
  .custom-list {
    list-style-type: none; /* 去除列表项前的默认标记 */
    padding-left: 0; /* 去除默认的内边距 */
  }
  .input-field {
    padding: '10px';
    width: '100%';
    margin-bottom: '20px';
    border: '1px solid #ccc';
    border-radius: '4px';
  }

  