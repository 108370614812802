body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f0f0;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
  }
  
  .navbar-logo img {
    height: 50px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 16px;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }

  /* Add this CSS to style the homepage layout */
.welcome-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.left-content {
  flex: 6;
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.right-content {
  flex: 4;
  padding: 20px;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-button {
  padding: 10px 20px;
  background-color: #3490dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.login-button:hover {
  background-color: #2779bd;
}