.footer {
    display: flex;
    justify-content: space-between; /* 三个主要元素分布在底部导航的两端和中间 */
    align-items: center; /* 确保元素在垂直方向上居中对齐 */
    padding: 10px;
    background-color: #333;
    color: white;
  }
  
  .footer-links {
    display: flex;
    flex-direction:row; /* 将链接竖直排列 */
    align-items: flex-end; /* 链接靠右对齐 */
  }
  
  .footer-links a {
    margin: 2px 0; /* 调整链接之间的间距 */
    font-size: 12px; /* 调整字体大小 */
    color: white;
    text-decoration: none;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  
  .footer-center {
    text-align: center; /* 版权信息居中 */
    margin-bottom: 10px; 
    margin-top: 30px; 
    font-size: 12px;
  }
  
  .footer-left {
    text-align: left;  
    font-size: 12px;
    margin-left: 30px; 
    margin-bottom: 20px;
  }