.image-generator {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .image-generator h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .input-container {
    display: flex;
    flex-direction: row; /* 从 column 改为 row */
    gap: 15px; /* 保持间距 */
    justify-content: center; /* 居中显示 */
    flex-wrap: wrap; /* 超出容器宽度时换行 */
  }
  
  .input-field {
    max-width: 200px; /* 限制最大宽度 */
    width: 100%; /* 适应容器宽度 */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .generate-button {
    width: 100%;
    padding: 10px;
    background-color: #3490dc;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .generate-button:hover {
    background-color: #2779bd;
  }
  
  .generate-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
  
  .image-container {
    margin-top: 20px;
  }
  
  .generated-image {
    width: 100%;
    border-radius: 4px;
  }