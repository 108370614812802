.plans-pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1; /* 新增这行代码 */
  }
  
  .prices-container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Adjust the gap between each plan as needed */
  }
  
  .plan {
    flex: 1; /* This makes each plan box take equal width */
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 20px; /* Adjust or remove margin as needed */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* 更深的阴影增加立体感 */
    border-radius: 15px; /* 圆角边框 */
  }
  .popular {
    border-color: #5b9ed8; /* 更柔和的边框颜色 */
    background-color: #272d34; /* 更柔和的背景颜色 */
  }
  .subscription-options button {
    width: 100%; /* 让按钮宽度与父容器相同 */
    padding: 10px 20px; /* 调整按钮内部的上下内边距和左右内边距 */
    background-color: #ffa500; /* 设置按钮颜色为亮橙色 */
    color: white; /* 设置按钮文字颜色为白色 */
    border: none; /* 移除边框 */
    border-radius: 5px; /* 轻微圆角 */
    cursor: pointer; /* 鼠标悬停时显示指针 */
    transition: background-color 0.3s; /* 平滑过渡效果 */
  }
  
  .subscription-options button:hover {
    background-color: #ff8c00; /* 鼠标悬停时的按钮颜色 */
  }

  /* 在文件末尾添加 */
.link-button {
    background-color: transparent;
    color: #007bff; /* 或您页面上链接的颜色 */
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .link-button:hover,
  .link-button:focus {
    color: #0056b3; /* 鼠标悬停或聚焦时的颜色，根据需要调整 */
    text-decoration: none; /* 或保持下划线，根据需要调整 */
  }