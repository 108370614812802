.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

body {
  background-color: #000; /* 设置背景颜色为黑色 */
  color: #fff; /* 设置文字颜色为白色以确保可读性 */
}


.welcome-content {
  flex: 1; /* 让内容区域可伸缩，占据除 header 和 footer 外的所有可用空间 */
  overflow-y: auto; /* 如果内容过多，允许在Y轴方向滚动 */
  padding: 20px; /* 根据需要调整 */
}

.footer {
  flex-shrink: 0; /* 防止 footer 被压缩 */
}

