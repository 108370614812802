/* Navbar.css */
.navbar {
    background-color: transparent; /* 设置背景为透明 */
    color: white; /* 根据需要调整文字颜色以确保可读性 */
    display: flex;
    justify-content: space-between; /* 项目间隔开 */
    padding: 0.5rem; /* 根据需要调整内边距 */
  }
  
  .navbar-logo {
    font-size: 1.5rem; /* logo字体大小 */
  }
  
  .navbar-links {
    display: flex;
  }
  
  .navbar-links a {
    color: white; 
    text-decoration: none; 
    background-color: transparent; 
    padding: 0.5rem 0.5rem; 
    border: none;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .navbar-logo img {
    animation: rotate 5s linear infinite;
  }